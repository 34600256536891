import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.div`
  .content-address:nth-child(even) {
    background-color: #F5F6FA;     
  }
  .content-address:nth-child(odd) {
    background-color: #EFEEED;
  }
`

export const Table = styled.div`
  @media ${device.tablet} {
    overflow: auto;
    white-space: nowrap;
    width: 94%;
    margin: 0 auto;

    .container {
      .table-zebra {
        background-color: #F1EFEE;     
      }     
    }
  }

  @media ${device.desktopLG} {
    overflow: hidden;
  }  
`

export const TableContainer = styled.div`
  .subtitle {
    font-family: "Citrina", Helvetica, sans-serif;
    color: ${orange.dark}
  }
  .content {
    @media ${device.tablet} {
      min-width: 376px;
    }

    @media ${device.desktopLG} {
      min-width: 311px;
    }

    @media ${device.desktopXL} {
      min-width: 368px;
    }
  } 

  @media ${device.tablet} {
    display: inline-block;
  }

  @media ${device.tablet} {
    display: inline-block;
    vertical-align: middle;
  }

  &.top {
    @media ${device.tablet} {
      border-bottom: 3px solid ${grayscale[500]};
      width: 1128px;
    }
    @media ${device.desktopLG} {
      border-bottom: 3px solid ${grayscale[500]};
      width: 935px;
    }
    @media ${device.desktopXL} {
      border-bottom: 3px solid ${grayscale[500]};
      width: auto;
    }
  }

  div {
    height: 60px;
    align-items: center;
    text-align: right;
    padding: 4px;

    @media ${device.tablet} {
      display: flex;
      justify-content: flex-end;
    } 

    &.primary {
      justify-content: start;

      @media ${device.tablet} {
        text-align: left;
      }
    }
  }
`
